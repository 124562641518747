import './DailyItem.css';
import { FaWalking } from 'react-icons/fa';
import { MdOutlineSpeakerNotes, MdPlace, MdVideocam } from 'react-icons/md';
import { IconContext } from 'react-icons';

function DailyItem(props) {
  const { day, date, month, place, blog, video, photo, comment } = props;
  const monthName = [
    1,
    2,
    3,
    4,
    5,
    6,
    'July',
    'August',
    'September',
    'October',
    11,
    12,
  ];
  return (
    <div className='daily'>
      <IconContext.Provider value={{ className: 'react-icons' }}>
        <div className='th'>
          <FaWalking />
          {date}日目
        </div>
        <div className='low'>
          <div className='detailContainer'>
            <div className='detailLeft'>
              <div className='leftUp'>
                <div className='dayContainer'>
                  <div className='day'>{day}</div>
                  <div className='month'>{monthName[month]}</div>
                  <div className='year'>2022年</div>
                </div>
                <div className='detailInnerRight'>
                  <div className='inner'>
                    <MdPlace />
                    {place}
                  </div>
                  <div>
                    {blog && (
                      <a className='inner' href={blog}>
                        <MdOutlineSpeakerNotes />
                        blog
                      </a>
                    )}
                  </div>
                  <div>
                    {video && (
                      <a className='inner' href={video}>
                        <MdVideocam />
                        video
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div>{comment && <div className='comment'>{comment}</div>}</div>
            </div>
            <div className='detailRight'>
              <div className='photo'>
                <img src={photo} alt='' />
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </div>
  );
}

export default DailyItem;
