import { useEffect, useState } from 'react';
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import './App.css';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './env';
import { IconContext } from 'react-icons';
import { FaTwitter, FaHome } from 'react-icons/fa';
import DailyItem from './DailyItem';
import note from './img/note.png';
import headImg from './img/100.png';

const app = initializeApp(firebaseConfig);

function App() {
  const [articles, setArticles] = useState([]);

  const LastDate = () => {
    const goal = new Date(2022, 6, 7);
    goal.setDate(goal.getDate() + 100);
    const now = new Date();
    const diff = goal.getTime() - now.getTime(); // タイムスタンプにして差分を計算
    const countDown = Math.floor(diff / (1000 * 60 * 60 * 24));
    return countDown;
  };
  useEffect(() => {
    const db = getFirestore(app);
    const q = query(collection(db, 'dayly'), orderBy('date', 'desc'));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const art = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          art.push({
            day: data.day,
            date: data.date,
            month: data.month,
            place: data.place,
            blog: data.blog,
            video: data.video,
            photo: data.photo,
            comment: data.comment,
            visible: data.visible,
          });
          setArticles(art);
        });
      },
      () => {
        alert('エラーが発生しました。');
      }
    );
    return unsubscribe;
  }, []);

  return (
    <div className='globalContainer'>
      <IconContext.Provider value={{ className: 'react-icons2' }}>
        <header className='head'>
          <img src={headImg} alt='note' className='headImg' />
          <img src={note} alt='note' className='note' />
        </header>
        <div className='lastContainer'>
          <div className='lastHead'>もえみが休むまで</div>
          <div className='lastAt'>
            <div>あと</div>
            <div className='lastNum'>{LastDate()}</div>
            <div>日</div>
          </div>
        </div>
        <div className='body'>
          {articles.map(
            (value, index) =>
              value.visible && (
                <DailyItem
                  date={value.date}
                  day={value.day}
                  month={value.month}
                  place={value.place}
                  blog={value.blog}
                  video={value.video}
                  photo={value.photo}
                  comment={value.comment}
                  key={index}
                />
              )
          )}
        </div>
        <footer className='foot'>
          <div className='link'>
            <a
              className='linkInner'
              href='https://twitter.com/moemi_konsome?s=21&t=zVFmC5bOeiKm73UO34JU3A'
            >
              <FaTwitter />
              <div>Twitter</div>
            </a>
            <a className='linkInner' href='https://www.konsome.info'>
              <FaHome />
              <div>KONSOME&#43;offcial HP</div>
            </a>
          </div>
          <div className='copy'>&copy; 2022 KONSOME&#43;</div>
        </footer>
      </IconContext.Provider>
    </div>
  );
}

export default App;
